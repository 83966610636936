import { useEffect, useRef, useState } from "react";
import styles from "./WellmaChatBot.module.css";
import Markdown from "react-markdown";
import {
  CopyOutlined,
  LikeOutlined,
  DislikeOutlined,
  ReloadOutlined,
  ArrowRightOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Tooltip, message, Modal, Input, Spin } from "antd";
import copy from "copy-to-clipboard";
import { v4 as uuidv4 } from "uuid";
import { giveWellmaAiFeedbackAPI } from "../../api/wellmaAiFeedbackApis";

let chatId = uuidv4();
const { TextArea } = Input;

const WellmaChatBot = () => {
  const [streamData, setStreamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [textBox, setTextBox] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [feedbackData, setFeedbackData] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);

  const chatSectionRef = useRef(null);
  const isRunningRef = useRef(isRunning);

  useEffect(() => {
    setStreamData([
      {
        text: `
Hello my name is wellma, How can I assist you on your wellness journey today?
        `,
        sentBy: 1,
      },
    ]);
  }, []);

  useEffect(() => {
    if (streamData.length > 2) {
      chatSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [streamData]);

  useEffect(() => {
    isRunningRef.current = isRunning;
  }, [isRunning]);

  const copyToClipBoard = (text) => {
    copy(text);
    message.success({
      content: "Copied to clipboard!",
    });
  };

  const giveWellmaAiFeedbackAPIHelper = async () => {
    setFeedbackLoader(true);
    try {
      await giveWellmaAiFeedbackAPI({
        chatId: chatId,
        feedbackText: feedbackData?.feedbackText,
        feedbackType: feedbackData?.feedbackType,
        feedbackFor: feedbackData?.feedbackFor,
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setFeedbackLoader(false);
      closeFeedbackModalHelper();
    }
  };
  const openFeedbackModalHelper = async (feedbackFor, feedbackType) => {
    // open the modal
    setIsFeedbackModalOpen(true);
    // set data for feedback
    setFeedbackData({
      feedbackFor,
      feedbackType,
    });
  };

  const closeFeedbackModalHelper = () => {
    setIsFeedbackModalOpen(false);
    setFeedbackData();
  };

  const fetchData = async () => {
    if (!textBox) {
      return;
    }

    setIsRunning(true);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/wellmaAIChat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: textBox,
            chatId: chatId,
          }),
        }
      );

      if (!response.body || response.status !== 200) {
        console.error("Response does not have a readable stream");
        setLoading(false);
        setTextBox("");
        setStreamData((prev) => {
          const updatedArray = [...prev];
          updatedArray.pop();
          return updatedArray;
        });
        message.error({
          content:
            "Server is not responding, Please try again after some time!",
        });
        return;
      }

      const textDecoder = new TextDecoder();
      const reader = response.body.getReader();
      while (true) {
        if (!isRunningRef.current) {
          // Your logic when stopping
          setTextBox("");
          setLoading(false);
          break;
        }

        const { done, value } = await reader.read();
        if (done) {
          console.log("Stream ended");
          setLoading(false);
          setTextBox("");
          setIsRunning(false);
          break;
        }
        // Process the chunk of data as needed
        setStreamData((prev) => {
          const lastIndex = prev.length - 1;
          const updatedArray = [...prev];
          updatedArray[lastIndex] = {
            sentBy: updatedArray[lastIndex]?.sentBy,
            text: updatedArray[lastIndex]?.text + textDecoder.decode(value),
          };
          return updatedArray;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const regenerateLastPrompt = async () => {
    setIsRunning(true);
    setLoading(true);

    try {
      const lastUserInput = streamData.filter((i) => !i.sentBy);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/wellmaAIChat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: lastUserInput[lastUserInput?.length - 1].text,
            chatId: chatId,
          }),
        }
      );

      if (!response.body || response.status !== 200) {
        console.error("Response does not have a readable stream");
        setLoading(false);
        setTextBox("");
        setStreamData((prev) => {
          const updatedArray = [...prev];
          updatedArray.pop();
          return updatedArray;
        });
        message.error({
          content:
            "Server is not responding, Please try again after some time!",
        });
        return;
      }

      const textDecoder = new TextDecoder();
      const reader = response.body.getReader();
      while (true) {
        if (!isRunningRef.current) {
          // Your logic when stopping
          setTextBox("");
          setLoading(false);
          break;
        }

        const { done, value } = await reader.read();
        if (done) {
          console.log("Stream ended");
          setLoading(false);
          setTextBox("");
          setIsRunning(false);
          break;
        }
        // Process the chunk of data as needed
        setStreamData((prev) => {
          const lastIndex = prev.length - 1;
          const updatedArray = [...prev];
          updatedArray[lastIndex] = {
            sentBy: updatedArray[lastIndex]?.sentBy,
            text: updatedArray[lastIndex]?.text + textDecoder.decode(value),
            regenerated: true,
          };
          return updatedArray;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitPromptHandler = () => {
    if (textBox) {
      setStreamData([
        ...streamData,
        { text: textBox, sentBy: 0 },
        { sentBy: 1, text: "" },
      ]);

      fetchData();
    }
  };

  const regenerateLastPromptHandler = () => {
    setStreamData([...streamData, { sentBy: 1, text: "" }]);

    regenerateLastPrompt();
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headingSection}>
        <div className={styles.headingSectionTopPart}>
          <img src="./images/WellmaBotLogo.png" alt="wellma" />
        </div>
        <div className={styles.headingSectionDisclaimer}>
          Wellma AI is in beta training. Consider checking important
          information.
        </div>
      </div>
      <div className={styles.chatSection}>
        {streamData?.length
          ? streamData?.map((item, index) => (
              <div
                key={index}
                className={
                  item?.sentBy ? styles.messageByAI : styles.messageByUser
                }
              >
                {item?.sentBy ? (
                  <div className={styles.messageByAIInnerDiv}>
                    <Markdown
                      className={styles.markdownTextArea}
                      components={{
                        a: ({ node, children, ...props }) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            {...props}
                          >
                            {children}
                          </a>
                        ),
                      }}
                    >
                      {item?.text}
                    </Markdown>
                    {loading &&
                    streamData?.length - 1 === index &&
                    index !== 0 ? (
                      <div className={styles.animatedLoader}></div>
                    ) : null}
                  </div>
                ) : (
                  <Markdown
                    className={styles.markdownTextArea}
                    components={{
                      a: ({ node, children, ...props }) => (
                        <a target="_blank" rel="noopener noreferrer" {...props}>
                          {children}
                        </a>
                      ),
                    }}
                  >
                    {item?.text}
                  </Markdown>
                )}
                {loading &&
                streamData?.length - 1 === index ? null : item?.sentBy &&
                  index !== 0 ? (
                  <div className={styles.actionButtons}>
                    <Tooltip placement="top" title={"Copy"}>
                      <CopyOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => copyToClipBoard(item?.text)}
                      />
                    </Tooltip>
                    <Tooltip placement="top" title={"Good response"}>
                      <LikeOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          openFeedbackModalHelper(item?.text, "positive")
                        }
                      />
                    </Tooltip>
                    <Tooltip placement="top" title={"Bad response"}>
                      <DislikeOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          openFeedbackModalHelper(item?.text, "negative")
                        }
                      />
                    </Tooltip>
                    {streamData?.length - 1 === index ? (
                      <Tooltip placement="top" title={"Regenerate"}>
                        <ReloadOutlined
                          style={{ cursor: "pointer" }}
                          onClick={regenerateLastPromptHandler}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ))
          : null}
        <div ref={chatSectionRef}></div>
      </div>
      <div className={styles.inputSection}>
        <input
          type="text"
          placeholder="Write your prompt here"
          className={loading ? styles.disabledPromptInput : styles.promptInput}
          value={textBox}
          onChange={(e) => setTextBox(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value) submitPromptHandler();
          }}
          disabled={loading}
        />
        {isRunning ? (
          <button
            className={styles.submitButton}
            onClick={() => setIsRunning(false)}
          >
            <StopOutlined />
          </button>
        ) : (
          <button className={styles.submitButton} onClick={submitPromptHandler}>
            <ArrowRightOutlined />
          </button>
        )}
      </div>
      {/* <div className={styles.disclaimerLine}>
        Copyright © 2022-23 HT Collective, Inc. All rights reserved.
      </div> */}
      {/* Feedback modal */}
      <Modal
        title="Provide feedback"
        open={isFeedbackModalOpen}
        onOk={giveWellmaAiFeedbackAPIHelper}
        onCancel={closeFeedbackModalHelper}
      >
        <Spin spinning={feedbackLoader}>
          <TextArea
            style={{ marginBottom: "24px" }}
            value={feedbackData?.feedbackText}
            onChange={(e) =>
              setFeedbackData((prev) => {
                const finalData = { ...prev };
                finalData.feedbackText = e.target.value;
                return finalData;
              })
            }
            maxLength={300}
            showCount={true}
            rows={5}
            placeholder={
              feedbackData?.feedbackType === "positive"
                ? "What you like about the response?"
                : "What was the issue with the response?"
            }
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default WellmaChatBot;
