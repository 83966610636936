import { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { Drawer } from "antd";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  let location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach resize listener
    window.addEventListener("resize", handleResize);

    // Clean up listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerNavigationWrapper}>
        <div className={styles.headerLogo}>
          <img src="/images/wellmalogo.png" alt="logo" />
        </div>
        {windowWidth >= 768 ? (
          <div className={styles.navigation}>
            <div className={styles.navigationItem}>
              <a
                href="https://www.wellmahealth.com/"
                target="_blank"
                rel="noreferrer"
              >
                Download app
              </a>
            </div>

            <div className={styles.navigationItem}>
              {location?.pathname === "/about" ? (
                <Link to={"/"}>Chat</Link>
              ) : (
                <Link to={"/about"}>About Us</Link>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={styles.navigationButton} onClick={showDrawer}>
              ☰
            </div>
            <Drawer
              title="Menu"
              placement="right"
              onClose={onClose}
              visible={visible}
              width={250}
            >
              <div className={styles.navigation}>
                <div className={styles.navigationItem}>
                  <a href="https://www.wellmahealth.com/aboutus">
                    Download app
                  </a>
                </div>
                <div className={styles.navigationItem}>
                  {location?.pathname === "/about" ? (
                    <Link to={"/"}>Chat</Link>
                  ) : (
                    <Link to={"/about"}>About Us</Link>
                  )}
                </div>
              </div>
            </Drawer>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
