import React from "react";
import styles from "./AboutUs.module.css";
import PageWrapper from "../../components/PageWrapper";

const AboutUs = () => {
  return (
    <PageWrapper>
    <div className={styles.mainContainer}>
      <h2>About Wellma.AI</h2>
      <p>
        Welcome to Wellma.ai, your AI-driven wellness companion. At Wellma
        Health, we're on a mission to revolutionize the way individuals approach
        holistic health and wellness. Our innovative platform, Wellma.ai,
        embodies this mission by blending AI technology with the rich knowledge
        base of holistic wellness practices. Here's everything you need to know
        about Wellma.ai and how you can be a part of this transformative
        journey.
      </p>

      <span>
        <h2>Our Vision</h2>
        <p>
          Our vision is to make holistic health practices accessible,
          understandable, and actionable for everyone. We believe that wellness
          goes beyond physical health to include emotional, mental, and
          spiritual well-being. Wellma.ai is designed to guide you through this
          comprehensive wellness journey by offering personalized
          recommendations, connecting you with experienced practitioners, and
          providing educational content that empowers you to lead a healthier,
          more balanced life.
        </p>
      </span>

      <span>
        <h2>Our Goal</h2>
        <p>
          The goal of Wellma.ai is to serve as a bridge between traditional
          wellness knowledge and modern technological convenience. We aim to
          create a user-friendly platform where anyone can come to learn about
          and practice wellness in a way that fits their individual needs and
          lifestyle. Whether you're new to holistic health or a seasoned
          practitioner, Wellma.ai seeks to enhance your wellness experience with
          personalized guidance and support.
        </p>
      </span>
      <span>
        <h2>Current State</h2>
        <p>
          Wellma.ai is currently in its alpha testing phase. This means we're
          actively seeking feedback from users like you to refine our AI's
          capabilities and ensure that Wellma.ai meets the real-world needs of
          our community. While Wellma.ai is a sophisticated tool, it's important
          to remember that it's still evolving. Your input is crucial to its
          development.
        </p>
      </span>
      <span>
        <h2>Expectations</h2>
        <p>
          As an alpha tester, you have the unique opportunity to shape the
          future of Wellma.ai. We encourage you to engage with Wellma.ai with an
          open mind and a spirit of exploration. Here's what you can expect:
        </p>
        <ul>
          <li> Personalized wellness recommendations based on AI analysis.</li>
          <li>
            {" "}
            A growing database of holistic health practices and practitioners.
          </li>
          <li>
            {" "}
            An intuitive and engaging user interface designed for easy
            navigation.
          </li>
          <li> Regular updates and improvements based on user feedback.</li>
        </ul>
      </span>

      <span>
        <h2>Join Us</h2>
        <p>
          By participating in the alpha test of Wellma.ai, you're not just
          trying out a new platform—you're joining a community of
          forward-thinkers who believe in the power of holistic wellness. Your
          feedback and insights during this phase are invaluable to us.
        </p>

        <p>
          Thank you for being a part of this exciting journey. Together, we can
          make wellness more accessible and personalized than ever before.
        </p>
      </span>
    </div>
    </PageWrapper>
  );
};

export default AboutUs;
