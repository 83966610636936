import CustomHeader from "./Header";

const PageWrapper = ({ children }) => {
  return (
    <div>
      <CustomHeader />
      <div>{children}</div>
    </div>
  );
};

export default PageWrapper;
