import axios from "../config/axiosConfig";

export const giveWellmaAiFeedbackAPI = async (data) => {
  try {
    const response = await axios.post(`/api/giveWellmaAiFeedback`, data);
    return response;
  } catch (error) {
    throw Error(error);
  }
};
