import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WellmaAI from '../page/WellmaAI';
import WellmaChatBot from '../page/WellmaChatBot';
import AboutUs from '../page/AboutUs';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WellmaAI />} />
        <Route path="/chatBot" element={<WellmaChatBot />} />
        <Route path="/about" element={<AboutUs />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
